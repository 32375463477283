.player-controls {
    display: flex;
    align-items: center;
    width: 156px;
    height: 40px;
}

.player-controls .control-btn {
    margin-right: 30px;
}

.player-controls .control-btn:last-of-type {
    margin-right: unset;
}