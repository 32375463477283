.profile-page {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 160px 60px 60px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 100vh;
    background-color: var(--dark-purple);
}