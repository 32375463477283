.tab-item {
    height: 20px;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    color: #7978AA;
    transition: .2s;
}

.tab-item:hover {
    color: white;
}

.tab-item_inactive {
    color: #7978AA;;
}

.tab-item_inactive:hover {
    color: #7978AA;
}