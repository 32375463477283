.station-switch {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    max-width: 980px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    align-items: center;
    justify-items: center;
    gap: 20px;
    padding: 32px;
    z-index: 2;
    overflow-y: clip;
    overflow-x: auto;
}

.station-switch::-webkit-scrollbar {
    height: 4px;
}

.station-switch::-webkit-scrollbar-track {
    background: #2f2f37;
    border-radius: 10px;
}

.station-switch::-webkit-scrollbar-thumb {
    background-color: #8585ad;
    border-radius: 10px;
    border: 0 none #ffffff;
}

@media (max-width: 1000px) {
    .station-switch {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 860px) {
    .station-switch .rmd-icon {
        width: 32px;
        height: 32px;
    }
}