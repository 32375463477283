.equalizer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 162px;
}

.player-container {
    height: 144px;
    background-color: #141437;
}

.player-container_full {
    display: flex;
}

.player-container_short {
    display: block;
}

.player {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.player__track-info,
.player__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 33%;
}

.player__track-info {
    justify-content: flex-start;
    gap: 14px;
}

.player__section {
    justify-content: flex-end;
    gap: 40px;
}

.player__station-name {
    color: #7978AA;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    margin-top: 12px;
}

@media (max-width: 1000px) {
    .equalizer-container {
        display: none;
    }

    .player {
        flex-direction: column;
        padding: 10px 0;
    }

    .player-container {
        height: auto;
        padding: 10px;
        box-sizing: border-box;
    }

    .player__track-info {
        display: none;
    }

    .player__volume-bar {
        display: none;
    }

    .player__section {
        justify-content: center;
        margin-top: 20px;
    }
}