.rmd-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all .2s;
}

.rmd-icon:hover * {
    opacity: .9;
}