.player-controls {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transform: translateY(6px);
}

.player-controls .circle-button {
    transform: translateY(-12px);
}

.player-controls .tippy-content {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    transform: translateX(24px);
    width: 60px;
    height: 190px;
}

.player-controls__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
}

@media (max-width: 1000px) {
    .player-controls {
        width: 100%;
        transform: unset;
    }
}