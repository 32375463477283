.time-line__duration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    padding: 0 20px;
}

.time-line__duration span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

:root {
    --timeline-background-color: #fff;
    --timeline-fill: linear-gradient(90.01deg, #4C4CFF 2.6%, #801AB3 99.99%);
    --timeline-height: 10px;
    --timeline-border-radius: 0 8px 8px 0;
}

.time-line__progress {
    position: relative;
    background-color: var(--timeline-background-color);
    border-radius: var(--timeline-border-radius);
    height: var(--timeline-height);
    opacity: 0.9;
}

.time-line__done {
    background: var(--timeline-fill);
    border-radius: var(--timeline-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media (max-width: 1000px) {
    .time-line__progress {
        margin-bottom: 20px;
    }
}