.track-cover {
    width: 78px;
    height: 78px;
    border-style: none;
}

.track-cover img {
    display: none;
}

.track-cover img[src] {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.track-name {
    display: flex;
    flex-direction: column;
}

.track-name__artist {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0;
}

.track-name__song {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
}